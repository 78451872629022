<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("GFO Checkout") }}</v-toolbar-title>
      <v-toolbar-title v-if="'PAR' == partner_type">, {{$t('order_for') }} &lt;{{ order_for_name }}&gt;
        <v-btn @click="gotoParForCustomerSelection">{{$t('order_for_change') }}</v-btn>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text style="position:relative;">
      <v-overlay v-if="show_overlay"
        style="margin:-15px;margin-top:0;"
        absolute></v-overlay>
      <!-- stepper muss da sein damit styles innerhalb der custom-component verfügbar -->
      <v-stepper v-if="loading">
        <v-stepper-header>
          <v-stepper-step step="1"> Konfiguration </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2"> weitere Angaben </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3"> Bestellung prüfen </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="4"> Fertig! </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <!-- date-picker muss da sein für die Styles -->
      <v-date-picker class="d-none"></v-date-picker>

      <v-skeleton-loader
        v-if="loading"
        type="card-avatar, article, actions"
      ></v-skeleton-loader>

      <gfo-form
        v-if="!loading && 'END' == partner_type"
        :data-api_url="api_url"
        :data-api_token="api_token"
        :data-tracking_reference="tracking_reference"
        :data-address_invoice="customer_address"
        :data-address_technical="partner_address"
        :data-partner_number="partner_number"
        :data-partner_type="partner_type"
        :data-theme="theme"
        :data-customer_number="customer_number"
        :data-netz="netz_slug"
        :data-checker_url="checker_url"
        :data-username="username"
        :data-order_type="order_type"
      >
      </gfo-form>

      <!-- WHO order  -->
      <gfo-form
        v-if="!loading && 'WHO' == partner_type"
        :data-api_url="api_url"
        :data-api_token="api_token"
        :data-tracking_reference="tracking_reference"
        :data-address_invoice="customer_address"
        :data-partner_type="partner_type"
        :data-theme="theme"
        :data-customer_number="customer_number"
        :data-netz="netz_slug"
        :data-checker_url="checker_url"
        :data-username="username"
        :data-order_type="order_type"
      >
      </gfo-form>

      <!-- Partner order for new customer -->
      <gfo-form
        v-if="!loading && 'PAR' == partner_type && '0' == order_for_number"
        :data-api_url="api_url"
        :data-api_token="api_token"
        :data-tracking_reference="tracking_reference"
        :data-partner_number="customer_number"
        :data-address_delivery="customer_address"
        :data-address_technical="customer_address"
        :data-partner_type="partner_type"
        :data-theme="theme"
        :data-netz="netz_slug"
        :data-checker_url="checker_url"
        :data-username="username"
        :data-order_type="order_type"
      >
      </gfo-form>

      <!-- Partner order for existing customer -->
      <gfo-form
        v-if="!loading && 'PAR' == partner_type && 
          '' != order_for_number && 
          '0' != order_for_number"
        :data-api_url="api_url"
        :data-api_token="api_token"
        :data-tracking_reference="tracking_reference"
        :data-customer_number="order_for_number"
        :data-partner_number="customer_number"
        :data-address_delivery="customer_address"
        :data-address_technical="customer_address"
        :data-partner_type="partner_type"
        :data-theme="theme"
        :data-netz="netz_slug"
        :data-checker_url="checker_url"
        :data-username="username"
        :data-order_type="order_type"
      >
      </gfo-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {getCookie} from "@/plugins/cookie.js"
import client from "@/plugins/axios.js"
export default {
  name: "ResellerGfoCheckout",
  props: {
    netz_slug: {
      type: [String],
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      loading: false,
      has_active_products: true,
      partner_number: null,
      partner_address: null,
      netz: null,
    };
  },
  computed: {
    checker_url: function () {
      let loc = this.$router.resolve({
        name: "gfo-internet-checker",
        params: {},
      });
      return loc ? loc.href : "";
    },
    customer_address: function () {
      let address = this.$store.state.session.address;
      return JSON.stringify({
        salutation: address.salutation,
        company_name: address.company_name,
        first_name: address.first_name,
        last_name: address.last_name,
        street: address.street,
        street_number: address.street_number,
        zip_code: address.zip_code,
        city: address.city,
        email: address.email,
        phone: address.phone,
        fax: address.fax,
        mobile: address.mobile,
        country: address.country,
        birthdate: address.birthdate,
      });
    },
    customer_number: function () {
      return this.$store.state.session.number;
    },
    theme: function () {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    partner_type: function () {
      return this.$store.state.session.partnerType;
    },
    order_for_number: function(){
      return this.$store.state.gfoWebcomponent.order_for.order_for_number
    },
    order_for_name: function(){
      return this.$store.state.gfoWebcomponent.order_for.order_for_name
    },
    order_for_has_active_products: function(){
      return this.$store.state.gfoWebcomponent.order_for.order_for_has_active_products
    },
    order_type: function(){
      if ('WHO' == this.partner_type){
        return 'who';
      } else if ('END' == this.partner_type) {
        if (!this.has_active_products){
          return "new"
        } else {
          return "end"
        }
      } else if ('PAR' == this.partner_type){
        if ("" != this.order_for_number && '0' != this.order_for_number && this.order_for_has_active_products) {
          return 'end';
        } else {
          return 'new';
        }
      } else {
        return 'end'; 
      }
    },
    show_overlay: function(){
      return this.loading || ('PAR' == this.partner_type && '' == this.order_for_number)
    },
    tracking_reference: function () {
      return "LIC_" + this.partner_type + "_{id}";
    },
    username: function(){
      let username = this.$store.state.session.name
      if (-1 < username.indexOf('#')){
        // Format: Azular GmbH #313235 (at)adrian.kuehnis, vorderer Teil ist unwichtig
        let parts = username.split("#") 
        parts.shift() 
        username = parts.join("#")
      }
      return "login.iway.ch:" + username
    },
    api_token: function () {
      if ("END" == this.partner_type || 
        ('PAR' == this.partner_type && '0' != this.order_for_number)
      ) {
        return this.$config.gfoApiTokenEnd;
      } else if ("WHO" == this.partner_type) {
        return this.$config.gfoApiTokenWho;
      } else {
        return this.$config.gfoApiTokenPar;
      }
    },
    api_url: function () {
      return this.$config.gfoApiUrl;
    },
  },
  methods: {
    gotoParForCustomerSelection: function(){
      if (this.netz != null){
        if (this.netz_slug == null || -1 < ["gf", "dsl"].indexOf(this.netz.type)){
          this.$router.push({name: "gfo-internet-checker"})
        } else {
          this.$router.push({name: "gfo-offer-" + this.netz.type})
        }
      }
    },
    loadScript: function () {
      let scripts = document.getElementsByTagName("script");
      let gfo_loader_found = false;
      for (var i = 0; i < scripts.length; i++) {
        if (scripts[i].src.endsWith("lic/gfo_form.js")) {
          gfo_loader_found = true;
        }
      }
      if (!gfo_loader_found) {
        let s = document.createElement("script");
        s.type = "text/javascript";
        document.getElementsByTagName("head")[0].appendChild(s);
        s.src = "https://gfo-apps.iway.ch/gfo_webcomponent/lic/gfo_form.js";
      }
    },
    loadPartner: async function (customer_id) {
      let http = this.$http;
      return new Promise(function (resolve, reject) {
        http
          .get("customers/" + customer_id + "/partner", {
            disableDefaultErrorHandler: true,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadCustomerData: async function(customer_id){
      let http = this.$http;
      return new Promise(function (resolve, reject) {
        http
          .get("customers/" + customer_id, {
            disableDefaultErrorHandler: true,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });

    },
    loadNetz: async function(){
      try {

        let response = await fetch (this.api_url + "/netze?slug=" + this.netz_slug, {
          method: "GET",
          headers: new Headers({
            "X-Api-Token": this.api_token
          })
        })
        let json = await response.json();
        if (json.length > 0) {
          this.netz = json[0];
        }
      } catch (err) {
        console.log("loadNetz Fehler: " + err);
      }

    },
    loadOrderFor: async function(){
      // Wird nur getriggert wenn partner_type=PAR und order_for_choices leer ist
      // Das ist nach einem Seitenreload der Fall
      // Lade aus Cookie aber verifiziere dass der Kunde für den Partner zulässig ist
      let that = this
      if ("PAR" == this.partner_type && -1 < location.href.indexOf('order_import=')){
        // Eine offerte wird geladen. Lader Bestellung von GFO
        // und prüfe ob die Kundennummer passt
        that.$store.commit("gfoWebcomponent/order_for", {
          order_by_number: '',
          order_for_number: '',
          order_for_name: that.$t('order_for_is_loading'),
          order_for_has_active_products: false
        })
        let order_reference = ""
        let search_parts = location.search.substring(1).split('&')
        search_parts.forEach(function(item){
          if (0 == item.indexOf("order_import=")){
            order_reference = item.replace("order_import=", "")
          }
        })
        try {
          let response = await fetch(that.api_url + "/orders/" + order_reference, {
            method: "GET",
            headers: {
              "x-api-token": that.api_token
            }
          })
          let order = await response.json()
          if (order.customer_number == null){
            // Offerte für Neukunden - kann verwendet werden
            that.$store.commit("gfoWebcomponent/order_for", {
              order_by_number: that.customer_number.toString(),
              order_for_number: "0",
              order_for_name: "für neuen Kunden",
              order_for_has_active_products: false
            })
            return
          } else if (that.customer_number == order.customer_number) {
            // Bestellung für mich selbst. Ist ok.
            that.$store.commit("gfoWebcomponent/order_for", {
              order_by_number: that.customer_number.toString(),
              order_for_number: that.customer_number.toString(),
              order_for_name: `mich selbst (KdNr ${that.customer_number})`,
              order_for_has_active_products: true // Gehe davon aus dass PAR aktive Produkte hat         
            })
          } else {
            // Prüfe ob Kundennummer zulässig
            let path = "/customers"
            if (0 < order.customer_number){
              path += "?q=" + order.customer_number
            }
            client.get(path, { disableDefaultErrorHandler: false })
            .then(response => {
              let found = false
              response.data.forEach(function (row) {
                if (row.number == order.customer_number) {
                  found = true
                  that.$store.commit("gfoWebcomponent/order_for", {
                    order_by_number: that.customer_number.toString(),
                    order_for_number: row.number.toString(),
                    order_for_name: row.name,
                    order_for_has_active_products: 0 < row.number_active_products    
                  })  
                }
              })
              if (!found){
                that.$store.commit("gfoWebcomponent/order_for", {
                  order_by_number: that.customer_number.toString(),
                  order_for_number: "",
                  order_for_name: "Fehler: Offerten-Kunde ist mir nicht zugeordnet.",
                  order_for_has_active_products: false    
                })
              }
            })
          }
        } catch (err){
          that.$store.commit("gfoWebcomponent/order_for", {
            order_by_number: '',
            order_for_number: '',
            order_for_name: err, 
            order_for_has_active_products: false    
          })
        }
      
      } else if ("PAR" == this.partner_type && '' == this.order_for_number){
        // Lade aus cookie und verifiziere
        let content = getCookie("gfo_order_for")
        try {
          let gfo_order_for = JSON.parse(content)
          if (gfo_order_for.order_by_number.toString() != this.customer_number.toString()){
            that.$store.commit("gfoWebcomponent/order_for", {
              order_by_number: '',
              order_for_number: '',
              order_for_name: 'Fehler - bitte Bestellprozess neu starten',
              order_for_has_active_products: false    
            })
            return // falscher Kunde - nichts tun
          }
          if (gfo_order_for.order_by_number.toString() == gfo_order_for.order_for_number.toString()){
            // Bestellung für PAR selbst. Speichern und zurück
            that.$store.commit("gfoWebcomponent/order_for", gfo_order_for);
            return
          }
          that.$store.commit("gfoWebcomponent/order_for", {
            order_by_number: '',
            order_for_number: '',
            order_for_name: that.$t('order_for_is_loading'),
            order_for_has_active_products: false    
          })
          let path = "/customers"
          if ("" != gfo_order_for.order_for_number && "0" != gfo_order_for.order_for_number){
            path += "?q=" + gfo_order_for.order_for_number
          }
          client.get(path, { disableDefaultErrorHandler: false })
            .then(response => {
              response.data.forEach(function (row) {
                if (
                  "0" == gfo_order_for.order_for_number || // neuer Kunde
                  row.number == gfo_order_for.order_for_number
                ) {
                  that.$store.commit("gfoWebcomponent/order_for", gfo_order_for)
                }
              })
            })
            .finally(function () {
                return
            });
        } catch(err){
          that.$store.commit("gfoWebcomponent/order_for", {
            order_by_number: '',
            order_for_number: '',
            order_for_name: err, 
            order_for_has_active_products: false    
          })
          return
        }
      } else {
        return
      }
    }
  },
  created: async function () {
    this.loadOrderFor()
    this.loading = true;
    this.loadScript();
    try {
      let resp = await this.loadPartner(this.$store.state.session.id);
      if (resp.hasOwnProperty("data")) {
        this.partner_address = JSON.stringify(resp.data.address);
        this.partner_number = resp.data.number;
      }
    } catch (e) {
      // pass
    }
    try {
      let resp = await this.loadCustomerData(this.$store.state.session.id);
      if (resp.hasOwnProperty("number_active_products")) {
        this.has_active_products = 0 < resp.number_active_products
      }
    } catch (e) {
      //pass
    }
    this.loading = false;
    this.loadNetz();
    
  },
};
</script>

<i18n>
{
  "en": {
   "order_for":"Order for",
   "order_for_change":"Change",
   "order_for_is_loading":"loading"
  },
  "de": {
   "order_for":"Bestellung für",
   "order_for_change":"Ändern",
   "order_for_is_loading":"wird geladen"
  }
}
</i18n>

